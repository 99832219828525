import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $readMores = $('.content-section.read-more');
    if (!$readMores.length > 0) return;

    $readMores.each((index, el) => {
        const $buttonContainer = $(
            '<div class="read-more__button"><button class="button button--primary">Read More</button></div>',
        );
        const $element = $(el);
        $element.append($buttonContainer);
        $buttonContainer.find('button').on('click', () => {
            $element.addClass('read-more--active');
        });
    });
});
